<template>
	<v-row justify="center">
		<v-col cols="12" md="11">
			<v-card>
				<v-img
					height="250px"
					:src="editedCollection.banner !== '' ? editedCollection.banner : `https://picsum.photos/id/1018/1280/853`"
				>
					<v-row>
						<v-col class="text-right" cols="12">
							<v-menu bottom left transition="slide-y-transition">
								<template v-slot:activator="{ on, attrs }">
									<v-btn
										icon dark
										v-bind="attrs"
										v-on="on"
									>
										<v-icon>mdi-dots-vertical</v-icon>
									</v-btn>
								</template>
								<v-list>
									<v-list-item>
										<v-list-item-action>
											<v-icon>mdi-file-image-plus</v-icon>
										</v-list-item-action>
										<v-list-item-content>
											<v-list-item-title>Change Header</v-list-item-title>
										</v-list-item-content>
									</v-list-item>
								</v-list>

							</v-menu>
						</v-col>
					</v-row>
					<v-row class="pa-4" align="center" justify="center">
						<v-col class="text-center">
							<h3 class="text-h5 white--text">{{editedCollection.name}}</h3>
							<span class="grey--text text--lighten-2">{{ editedCollection.description }}</span>
						</v-col>
					</v-row>
				</v-img>
				<!-- <v-toolbar color="primary">
					<v-toolbar-title>Collection - {{collectionName}}</v-toolbar-title>
				</v-toolbar> -->
				<v-card-text>
          <template v-if="editMode">
            <v-form ref="addCategory">

              <v-row class="text-center" justify="center" dense>
                
                <!-- Header Image -->
                <v-col cols="12" md="6" v-if="editMode">
                  <v-file-input persistent-hint hint="Upload a banner to appear on the top of collection."
                    filled
                    :rules="rules"
                    accept="image/png, image/jpeg, image/bmp"
                    placeholder="Pick a banner image"
                    prepend-icon="mdi-camera"
                    label="Banner"
                    @change="imageChanged"
                  ></v-file-input>
                </v-col>

                <!-- Collection Name -->
                <v-col cols="12" md="6">
                  <v-text-field persistent-hint hint="Give your Collection a unique name."
                    :readonly="!editMode"
                    filled
                    label="Name - Required"
                    placeholder="Christmas Collection"
                    v-model="editedCollection.name"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12">
                  <v-textarea persistent-hint hint="Go into the Collection's details."
                    :readonly="!editMode"
                    filled
                    v-model="editedCollection.description"
                    label="Description - Optional"
                    auto-grow
                  ></v-textarea>
                </v-col>
              </v-row>

              <v-row dense>
                <v-col cols="12">
                  <!-- Recipe Selectors -->
                  <v-autocomplete persistent-hint hint="Include recipes in your collection."
                    return-object
                    v-model="collectionRecipes"
                    label="Recipes - Suggested"
                    :items="storeRecipes"
                    item-text="name"
                    filled
                    chips
                    multiple
                  >
                    <template v-slot:selection="data">
                      <v-chip
                        close
                        v-bind="data.attrs"
                        :input-value="data.selected"
                        @click="data.select"
                        @click:close="remove(data.item)"
                      >
                        <v-avatar left color="blue">
                          <v-img v-if="data.item['thumbnail'] !== undefined"
                            :src="data.item.thumbnail"
                          ></v-img>
                          <v-icon dark small v-else>
                            mdi-glass-cocktail
                          </v-icon>
                        </v-avatar>
                        {{data.item.name}}
                      </v-chip>
                    </template>
                    <template v-slot:item="data">
                      <v-list-item-avatar v-if="data.item['thumbnail'] === undefined">
                        <v-avatar color="blue">
                          <v-icon dark>
                            mdi-glass-cocktail
                          </v-icon>
                        </v-avatar>
                      </v-list-item-avatar>
                      <v-list-item-avatar v-else>
                        <img :src="data.item.thumbnail">
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title v-html="data.item.name"></v-list-item-title>
                        <v-list-item-subtitle v-html="shortenedIngreds(data.item.ingredients)"></v-list-item-subtitle>
                      </v-list-item-content>
                    </template>
                  </v-autocomplete>
                </v-col>
              </v-row>

            </v-form>
          </template>
          <template v-else>
          <v-row dense>
            <v-col v-for="recipe in editedCollection.recipes" :key="recipe.id"
              cols="12" md="4"
            >
              <!-- Recipe Cards -->      
              <v-card class="fill-height rounded-xl d-flex align-content-space-between" style="flex-direction: column;">
                <v-toolbar flat class="flex-grow-0" :color="recipe.type == 'popular' ? 'indigo' : recipe.type == 'searchResult' ? 'teal' : 'indigo'" dark>
                  <v-toolbar-title>{{recipe.name}}</v-toolbar-title>
                  <v-spacer></v-spacer>
                  <!-- <v-btn v-if="recipeIsEditable(recipe)" icon :to="`/recipe/${recipe.id}`">
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn> -->
                </v-toolbar>
                <v-card-text>

                  <!-- Recipe Ingredients -->          
                  <v-subheader>Ingredients</v-subheader>
                  <v-row>
                    <v-col cols="12" class="pt-0">
                      <v-chip v-for="item in recipe.ingredients" :key="item.id" 
                        dark 
                        :color="ownsIngredient(item.type) ? 'green' : 'red'" 
                        class="ma-2"
                      >
                        {{getIngredientName(item)}}
                      </v-chip>
                    </v-col>
                  </v-row>

                  <!-- Recipe Steps -->          
                  <v-subheader>Steps</v-subheader>
                  <v-row>
                    <v-col cols="12" class="pt-0">
                      <ol>
                        <li v-for="(step, index) in recipe.steps" :key="index">{{step}}</li>
                      </ol>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <div class="text-caption" v-if="recipe.source">
                        Source: <a :href="recipe.source.link">{{recipe.source.name}}</a>
                      </div>
                    </v-col>
                  </v-row>
                </v-card-text>

                <!-- Recipe Actions -->        
                <v-card-actions class="mt-auto">
                  <!-- <v-btn text>
                    Submit to Dictionary
                    <v-icon right>mdi-book-plus</v-icon>
                  </v-btn> -->
                  <v-spacer></v-spacer> 
                  
                  <!-- Make -->
                  <!-- <template v-if="user.loggedIn && recipeIsEditable(recipe)">
                    <v-btn rounded text color="purple" disabled>
                      <v-icon left>
                        mdi-glass-cocktail
                      </v-icon>
                      Make
                    </v-btn>
                  </template> -->
                  
                  <!-- Share -->
                  <!-- <template v-if="recipeIsEditable(recipe)">
                    <v-bottom-sheet inset>
                      <template v-slot:activator=" { on, attrs }">
                        <v-btn v-on="on" v-bind="attrs" rounded text color="info">
                          <v-icon left>mdi-share</v-icon>
                          Share
                        </v-btn>      
                      </template>
                      <v-list>
                        <v-subheader>Share Recipe</v-subheader>
                        
                        <v-dialog width="500" v-model="qrcodeDialog">
                          <template v-slot:activator="{ on, attrs }">
                            <v-list-item v-on="on" v-bind="attrs">
                              <v-list-item-avatar>
                                <v-avatar size="32px">
                                  <v-icon>mdi-qrcode</v-icon>
                                </v-avatar>
                              </v-list-item-avatar>
                              <v-list-item-title>
                                QR Code
                              </v-list-item-title>
                            </v-list-item>
                          </template>
                          <v-card>
                            <v-toolbar flat>
                              <v-toolbar-title>Scan Me!🤳</v-toolbar-title>
                            </v-toolbar>
                            <v-card-text>
                              <v-row>
                                <v-col>
                                    <v-img class="ma-auto" height="150" width="150" :src="generateQrCode(recipe.id)"></v-img>
                                </v-col>
                              </v-row>
                            </v-card-text>
                            <v-card-actions>
                              <v-btn
                                color="primary"
                                text
                                @click="qrcodeDialog = false"
                              >
                                Close
                              </v-btn>
                            </v-card-actions>
                          </v-card>
                        </v-dialog>
                        
                        <v-list-item @click="webShare(recipe)">
                          <v-list-item-avatar>
                            <v-avatar size="32px">
                              <v-icon>mdi-share</v-icon>
                            </v-avatar>
                          </v-list-item-avatar>
                          <v-list-item-title>
                            Share via Phone
                          </v-list-item-title>
                        </v-list-item>
                        
                        <v-list-item @click="copyUrl(recipe.id)">
                          <v-list-item-avatar>
                            <v-avatar size="32px">
                              <v-icon>mdi-content-copy</v-icon>
                            </v-avatar>
                          </v-list-item-avatar>
                          <v-list-item-title>
                            Copy Link
                          </v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-bottom-sheet>
                  </template> -->

                  <!-- Add to Stash -->
                  <template v-if="user.loggedIn">
                    <v-btn rounded text color="indigo" @click="addToRecipes(recipe)">
                      <v-icon left>mdi-book-plus-multiple-outline</v-icon>
                      Add to Recipes
                    </v-btn>
                  </template>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </template>
				</v-card-text>

				<v-card-actions v-if="editMode">
          <v-spacer></v-spacer>
          <v-btn color="success" @click="saveCollection">
            Save
            <v-icon right>
              mdi-content-save
            </v-icon>
          </v-btn>
        </v-card-actions>	
				
			</v-card>
		</v-col>
	</v-row>
</template>

<script>
  import { DB } from '../firebase/db.js'
	import {  mapState } from 'vuex';
	import { Storage } from '../firebase/storage.js';

	export default {
		name: 'AddCollection',
		data: () => ({
      editMode: false,
      collection: {},
      editedCollection: {},
		}), 
    mounted(){
      var id = this.$route.params.id;
      var foundCollection = this.$store.getters.getCollectionById(id);

      if(this.$route.path.indexOf('/edit') >= 0){
        this.editMode = true;
      }

      if(foundCollection === undefined){
        let ref = DB.collection('collections').doc(id);
        ref.get()
          .then( document => {
            if(document.exists){
              var doc = document.data();
              if(doc.ownerId == this.user.data.id){
                this.collection = {id: document.id, ...document.data()}
                this.editedCollection = {id: document.id, ...document.data()}
              }
            }
          })
      } else {
        this.collection = foundCollection;
        this.editedCollection = {...this.collection};
      }
    },
		methods: {
			imageChanged(input){
				if(input === null){
					this.uploadedImage = ''; 
					this.uploadedImageUrl = '';
					return;
				}

				this.uploadedImage = input;
				this.uploadedImageUrl = URL.createObjectURL(input);
			}, 
			saveCollection(){
				// Upload the image
				if(this.uploadedImage !== null || this.uploadedImage !== undefined){
					this.uploadingImage = true;
					var storageRef = Storage.ref();
					var imagesCollectionsRef = storageRef.child(`images/${this.user.data.id}/collections/${this.uploadedImageFileName}.${this.uploadedImage.name.split('.').pop()}`);

					var uploadTask = imagesCollectionsRef.put(this.uploadedImage);
					uploadTask.on('state_changed', 
						(snapshot) => {
							var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
							if(progress === 100){
								this.uploadingImage = false;
								this.$emit('showAlert', {text: 'Sucessfully uploaded your image!', type: 'success'});
							}
						}, 
						(error) => {
							this.$emit('showAlert', {text: 'There was an error uploading your image. Check the console.', type: 'error'});
							console.log(error);
						}, 
						() => {
							uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
								this.uploadedImageDownloadURL = downloadURL;
								this.$emit('showAlert', {text: 'The file was uploaded successfull.', type: 'success'});
							});
						}
					);
				}

        // Add to firestore
				this.$store.dispatch('addCollection', {
					banner: this.uploadedImageDownloadURL, 
					name: this.collectionName, 
					description: this.collectionDescription, 
					recipes: this.collectionRecipes
				})

			}, 
			shortenedIngreds(ingredients){
				return ingredients.reduce((prev,curr, curIndex) => {
					let seperator = curIndex === 0 ? '' : ' - '

					if(typeof curr.type === 'object'){
						return prev + seperator + curr.type.name
					}
					return prev + seperator + curr.type
					
				}, '')
			},
      ownsIngredient(ingredient){
        return this.$store.getters.getInventoryByIngredient(ingredient);
      }, 
      getIngredientName(ingredient){        
        var normalizedIngredientName = '';
        if(typeof ingredient.type == 'string'){
          let ingredType = ingredient.type;
          normalizedIngredientName = ingredType;
        } else if(typeof ingredient.type == 'object'){
          let ingredType = ingredient.type.name;
          normalizedIngredientName = ingredType; 
        }
        return `${ingredient.qty != null ? ingredient.qty : ''} ${ingredient.measurement} - ${normalizedIngredientName}`
      },
		}, 
		computed: {
			...mapState({
				storeCollections: 'collections', 
				user: 'user'
			}), 
      editable(){
        return this.collection.ownerId == this.user.data.id;
      }
		}
	}
</script>